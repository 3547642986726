import React, { createContext, useContext, ReactNode, useState } from 'react'

import {
  Unit,
  Lesson,
  Activity,
  ActivityTest,
  ListActivities,
} from 'types/graphql'

type ActivityOrActivityTest = Activity | ActivityTest

interface ActivityState {
  activity: ActivityOrActivityTest

  /** @deprecated Use activitiesList instead */
  activities: ActivityOrActivityTest[]

  activitiesList: ListActivities
  activityUnit: Unit
  activityLesson: Lesson

  setActivity: React.Dispatch<React.SetStateAction<ActivityOrActivityTest>>

  /** @deprecated Use setActivitiesList instead */
  setActivities: React.Dispatch<React.SetStateAction<ActivityOrActivityTest[]>>

  setActivityUnit: React.Dispatch<React.SetStateAction<Unit>>
  setActivityLesson: React.Dispatch<React.SetStateAction<Lesson>>
  setActivitiesList: React.Dispatch<React.SetStateAction<ListActivities>>
}

const ActivityContext = createContext<ActivityState | undefined>(undefined)

const useActivity = (): ActivityState => {
  const context = useContext(ActivityContext)

  if (!context) {
    throw new Error('useActivity must be used within an ActivityProvider')
  }

  return {
    activity: context.activity,
    activities: context.activities,
    activitiesList: context.activitiesList,
    activityUnit: context.activityUnit,
    activityLesson: context.activityLesson,
    setActivity: context.setActivity,
    setActivities: context.setActivities,
    setActivityUnit: context.setActivityUnit,
    setActivityLesson: context.setActivityLesson,
    setActivitiesList: context.setActivitiesList,
  }
}

type ActivityProviderProps = {
  children: ReactNode
}

const ActivityProvider = ({ children }: ActivityProviderProps) => {
  const [activity, setActivity] = useState<ActivityOrActivityTest>()
  const [activities, setActivities] = useState<ActivityOrActivityTest[]>([])
  const [activityUnit, setActivityUnit] = useState<Unit>()
  const [activityLesson, setActivityLesson] = useState<Lesson>()
  const [activitiesList, setActivitiesList] = useState<ListActivities>()

  const value = {
    activity,
    activityUnit,
    activityLesson,
    activities,
    activitiesList,
    setActivity,
    setActivities,
    setActivityUnit,
    setActivityLesson,
    setActivitiesList,
  }

  return (
    <ActivityContext.Provider value={value}>
      {children}
    </ActivityContext.Provider>
  )
}

export { ActivityProvider, useActivity, ActivityContext }
