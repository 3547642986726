import store from 'store2'

export const getStoreLevel = () => {
  const storedLevel = JSON.parse(store('level'))
  if (storedLevel) {
    return storedLevel
  }

  // TODO: Remove this in the future
  // fallback for the store key change from 'course' to 'level'
  const storedCourse = JSON.parse(store('course'))
  if (storedCourse) {
    setStoreLevel({ id: storedCourse.id })
    store.remove('course')

    return storedCourse
  }

  return {}
}

export const setStoreLevel = ({ id }: { id: number }) => {
  store('level', JSON.stringify({ id }))
}
