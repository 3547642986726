import { useLazyQuery } from '@apollo/client'
import store from 'store2'
import {
  ActivityAnswerField,
  ActivityAnswerFieldTest,
  GetLessonsList,
  GetUnits,
} from 'types/graphql'

import { routes, navigate, useParams } from '@redwoodjs/router'

import { TypologyEnum } from 'src/enums/learning/activity/TypologyEnum'
import { GET_LESSONS, GET_UNITS } from 'src/graphql/learning/learning'
import { getStoreLevel } from 'src/helpers/storage'

interface RandomSortableItem {
  id: number
  text: string
  coverMedia: unknown
  isCorrect?: boolean
}

const randomSortable = (array: RandomSortableItem[]) => {
  if (array.length < 2) return array

  const shuffler = array.slice()

  const entropy = Math.random()

  for (let increment = shuffler.length - 1; increment > 0; increment--) {
    const random = Math.floor(((Math.random() + entropy) % 1) * (increment + 1))

    const temp = shuffler[increment]
    shuffler[increment] = shuffler[random]
    shuffler[random] = temp
  }

  return shuffler[0] === array[0] && shuffler.length > 1
    ? [shuffler[1], shuffler[0], ...shuffler.slice(2)]
    : shuffler
}

const useRandomChoices = (
  answerFields: ActivityAnswerField[] | ActivityAnswerFieldTest[]
) => {
  const choices = answerFields.flatMap((item) =>
    item.choices.map((choice) => ({
      id: choice.id,
      text: choice.text,
      coverMedia: choice.coverMedia,
      isCorrect: choice?.isCorrect,
    }))
  )
  return randomSortable(choices)
}
const useActivityTitle = (typology: string) => {
  const typologyTitle: { [key in TypologyEnum]: string } = {
    [TypologyEnum.SEQUENCING]: 'Sequencing',
    [TypologyEnum.MULTIPLE_CHOICE]: 'Multiple Choice',
    [TypologyEnum.FILL_IN_THE_BLANKS]: 'Fill in the Blanks',
    [TypologyEnum.COMBINATION]: 'Combination',
    [TypologyEnum.WRITING]: 'Writing',
  }

  return typologyTitle[typology]
}

const useNextLesson = (onFinish?: () => void) => {
  const { unitId, lessonId } = useParams()

  const storeLevel = getStoreLevel()

  const [getLessons, { loading: loadingLessons, refetch: refetchLessons }] =
    useLazyQuery<GetLessonsList>(GET_LESSONS)

  const [getUnits, { loading: loadingUnits }] =
    useLazyQuery<GetUnits>(GET_UNITS)

  const loading = loadingLessons || loadingUnits

  const goToNextLesson = async () => {
    const response = await getLessons({
      variables: {
        unitId,
      },
    })

    const lessons = response?.data?.GetLessons
    const currentLesson = lessons?.find(
      (lesson) => lesson.id === Number(lessonId)
    )
    const nextLesson = lessons?.find(
      (lesson) => lesson?.order === currentLesson?.order + 1
    )

    if (nextLesson?.id) {
      const { id, title } = nextLesson
      store('lesson', JSON.stringify({ id, title }))

      if (onFinish) onFinish()

      navigate(
        routes.learningActivityExecutionActivityPage({
          lessonId: id,
          unitId: Number(unitId),
        })
      )
    } else {
      goToNextUnitFirstLesson()
    }
  }

  const goToNextUnitFirstLesson = async () => {
    const response = await getUnits({
      variables: {
        levelId: storeLevel?.id,
      },
    })

    const units = response?.data?.GetUnits

    const currentUnit = units?.find((unit) => unit.id === Number(unitId))
    const nextUnit = units?.find(
      (unit) => unit?.order === currentUnit?.order + 1
    )

    if (nextUnit) {
      const { id: nextUnitId, title: nextUnitTitle } = nextUnit

      const refetchResponse = await refetchLessons({
        unitId: nextUnitId,
      })

      if (refetchResponse?.data?.GetLessons) {
        const nextUnitLesson = refetchResponse?.data?.GetLessons?.find(
          (lesson) => lesson.order === 0
        )
        const { id: nextUnitLessonId, title: nextUnitLessonTitle } =
          nextUnitLesson

        store('unit', JSON.stringify({ id: nextUnitId, title: nextUnitTitle }))
        store(
          'lesson',
          JSON.stringify({ id: nextUnitLessonId, title: nextUnitLessonTitle })
        )

        if (onFinish) onFinish()

        navigate(
          routes.learningActivityExecutionActivityPage({
            lessonId: nextUnitLessonId,
            unitId: nextUnitId,
          })
        )
      }
    }
  }

  return { goToNextLesson, loading }
}

export { useRandomChoices, useActivityTitle, useNextLesson }
