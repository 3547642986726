import React, {
  createContext,
  useContext,
  ReactNode,
  useCallback,
  useState,
} from 'react'

import { ModuleProgress, StudentLevel } from 'types/graphql'
import { LevelUnit } from 'types/levelUnit'

type Props = {
  children: ReactNode
}

type Levels = {
  currentLevel: StudentLevel
  levels: StudentLevel[]
}

type LevelsState = {
  levels: StudentLevel[]
  currentLevel: StudentLevel
  levelUnits: LevelUnit[]
  courseProgress: ModuleProgress[]
  setLevels: (levels: Levels) => void
  setLevelsUnits: (levelUnits: LevelUnit[]) => void
  setCourseProgress: (courseProgress: ModuleProgress[]) => void
}

const LevelContext = createContext<LevelsState | undefined>(undefined)

const useLevel = (): LevelsState => {
  const context = useContext(LevelContext)

  if (!context) {
    throw new Error('useLevel must be used within LevelProvider')
  }

  return {
    levels: context.levels,
    currentLevel: context.currentLevel,
    levelUnits: context.levelUnits,
    courseProgress: context.courseProgress,
    setLevels: context.setLevels,
    setLevelsUnits: context.setLevelsUnits,
    setCourseProgress: context.setCourseProgress,
  }
}

const LevelProvider = ({ children }: Props) => {
  const [levelUnits, setLevelUnitsObject] = useState<LevelUnit[]>([])
  const [courseProgress, setCourseProgressObject] = useState<ModuleProgress[]>(
    []
  )

  const [levelsObject, setLevelsObject] = useState<Levels>({
    currentLevel: {} as StudentLevel,
    levels: [] as StudentLevel[],
  })

  const setLevelsAndCurrentLevel = useCallback((levels: Levels) => {
    setLevelsObject(levels)
  }, [])

  const setLevelsUnits = useCallback((levelUnits: LevelUnit[]) => {
    setLevelUnitsObject(levelUnits)
  }, [])

  const setCourseProgress = useCallback(
    (courseProgress: ModuleProgress[]) =>
      setCourseProgressObject(courseProgress),
    []
  )

  const value = {
    currentLevel: levelsObject.currentLevel,
    levels: levelsObject.levels,
    levelUnits,
    courseProgress,
    setLevels: setLevelsAndCurrentLevel,
    setLevelsUnits,
    setCourseProgress,
  }

  return <LevelContext.Provider value={value}>{children}</LevelContext.Provider>
}

export { LevelProvider, useLevel, LevelContext }
