const GET_ACTIVITY = gql`
  query FindActivity($id: Int!) {
    FindActivity(id: $id) {
      id
      instruction_en
      instruction_pt_br
      contextMedia
      contextText
      typology
      estimatedTime
      maxAnswerTime
      modules {
        moduleId
        order
      }
      answerFields {
        coverMedia
        id
        text
        order
        choices {
          isCorrect
          id
          text
          order
          coverMedia
        }
        answers {
          id
          answer
          hit
          answerFieldId
          createdAt
          teacherFeedback
          teacherFeedbackAt
          teacherFeedbackBy
        }
      }
    }
  }
`

const GET_ACTIVITIES = gql`
  query FilterActivities($courseId: Int, $moduleId: Int, $status: StatusEnum) {
    FilterActivities(
      courseId: $courseId
      moduleId: $moduleId
      status: $status
    ) {
      id
      instruction_en
      instruction_pt_br
      contextMedia
      contextText
      typology
      estimatedTime
      maxAnswerTime
      modules {
        moduleId
        order
      }
      answerFields {
        coverMedia
        id
        text
        order
        choices {
          isCorrect
          id
          text
          order
          coverMedia
        }
        answers {
          id
          answer
          hit
          answerFieldId
          createdAt
        }
      }
    }
  }
`

const ACTIVITY_FIELDS_FRAGMENT = gql`
  fragment ActivityFields on Activity {
    id
    instruction_en
    instruction_pt_br
    contextMedia
    contextText
    typology
    estimatedTime
    maxAnswerTime
    modules {
      moduleId
      order
    }
    answerFields {
      coverMedia
      id
      text
      order
      choices {
        isCorrect
        id
        text
        order
        coverMedia
      }
      answers {
        id
        answer
        hit
        answerFieldId
        createdAt
      }
    }
  }
`

const GET_ACTIVITIES_LIST = gql`
  ${ACTIVITY_FIELDS_FRAGMENT}
  query ListActivities($status: StatusEnum, $moduleId: Int) {
    ListActivities(status: $status, moduleId: $moduleId) {
      required {
        ...ActivityFields
      }
      extra {
        ...ActivityFields
      }
    }
  }
`

const GET_SIMPLIFIED_MODULE_BY_COURSE = gql`
  query FindCourse($id: Int!) {
    FindCourse(id: $id) {
      modules {
        id
        title
        slug
      }
    }
  }
`

const GET_RESOURCES = gql`
  query FilterResources($moduleId: Int, $levelId: Int) {
    FilterResources(moduleId: $moduleId, levelId: $levelId) {
      id
      name
      media
      order
      module {
        id
        title
        module {
          order
        }
      }
    }
  }
`
const CREATE_ANSWER_ACTIVITY = gql`
  mutation AnswerActivity($input: UserAnswerInput!) {
    AnswerActivity(input: $input) {
      feedback {
        title_en
        title_pt_br
        explanation_en
        explanation_pt_br
        type
      }
      answers {
        id
        answer
        hit
        answerFieldId
      }
    }
  }
`

const GET_COURSE_PROGRESS = gql`
  query GetCourseProgress($courseId: Int!) {
    CourseProgress(courseId: $courseId) {
      modulesProgress {
        id
        completed
        total
      }
    }
  }
`

const GET_WRITING_ACTIVITIES = gql`
  query ListWritingAnswers($levelId: Int!) {
    WritingAnswers(levelId: $levelId) {
      answers {
        levelTitle
        unitId
        unitOrder
        unitTitle
        lessonOrder
        lessonId
        lessonTitle
        activityOrder
        activityId
        latestAnswerHit
        teacherFeedbackAt
        latestUserAnswerAt
      }
    }
  }
`

const GET_TEST = gql`
  query GetTest($id: Int!) {
    FindTest(id: $id) {
      startAt
      finishAt
      testGroupId
      testGroup {
        id
        title
      }
    }
  }
`

const GET_STUDENT_TEST = gql`
  query GetStudentTest($id: Int!) {
    FindStudentTest(id: $id) {
      id
      testId
      testModuleId
      startedAt
      mustFinishAt
      finishedAt
    }
  }
`

const START_TEST = gql`
  mutation StartTest($testId: Int, $accepted: Boolean) {
    StartTest(testId: $testId, accepted: $accepted) {
      studentTestId
      startedAt
    }
  }
`

const GET_TEST_ACTIVITIES = gql`
  query GetTestActivities($studentTestId: Int) {
    GetTestActivities(studentTestId: $studentTestId) {
      id
      answered
      instruction_en
      instruction_pt_br
      contextMedia
      contextText
      typology
      order
      answerFields {
        id
        coverMedia
        text
        order
        choices {
          id
          coverMedia
          order
          text
        }
        answers {
          id
          answer
          answerFieldId
        }
      }
    }
  }
`

const CREATE_ANSWER_TEST_ACTIVITY = gql`
  mutation AnswerTestActivity(
    $studentTestId: Int
    $userAnswerFields: UserAnswerInput!
  ) {
    AnswerTestActivity(
      studentTestId: $studentTestId
      userAnswerFields: $userAnswerFields
    ) {
      answers {
        id
        answer
        answerFieldId
      }
    }
  }
`

const CREATE_FINISH_TEST = gql`
  mutation CreateFinishTest($studentTestId: Int) {
    FinishTest(studentTestId: $studentTestId) {
      ok
    }
  }
`

const GET_STUDENT_TESTS = gql`
  query GetStudentTests {
    ListStudentTests {
      testsAvailableToStart {
        id
        title
        startAt
        finishAt
      }
      existingTests {
        id
        title
        startedAt
        mustFinishAt
        finishedAt
      }
    }
  }
`

const GET_UNIT = gql`
  query GetUnit($id: Int!) {
    GetUnit(id: $id) {
      id
      title
      description
      coverMedia
      order
      category
      status
    }
  }
`

const GET_UNITS = gql`
  query GetUnits($levelId: Int!) {
    GetUnits(levelId: $levelId) {
      id
      title
      description
      coverMedia
      order
      category
    }
  }
`

const GET_STUDENT_LEVELS = gql`
  query GetStudentLevels {
    GetStudentLevels {
      id
      title
      order
      description
      coverMedia
      courseId
      currentLevel
    }
  }
`

const GET_LESSON = gql`
  query GetLesson($id: Int!) {
    GetLesson(id: $id) {
      id
      title
      description
      totalEstimatedTime
      order
      category
      status
    }
  }
`

const GET_LESSONS = gql`
  query GetLessonsList($unitId: Int!) {
    GetLessons(unitId: $unitId) {
      id
      title
      description
      totalEstimatedTime
      order
      category
      status
    }
  }
`

export {
  GET_ACTIVITY,
  GET_ACTIVITIES,
  GET_SIMPLIFIED_MODULE_BY_COURSE,
  GET_RESOURCES,
  CREATE_ANSWER_ACTIVITY,
  GET_COURSE_PROGRESS,
  GET_WRITING_ACTIVITIES,
  GET_TEST,
  START_TEST,
  GET_STUDENT_TEST,
  GET_TEST_ACTIVITIES,
  CREATE_ANSWER_TEST_ACTIVITY,
  CREATE_FINISH_TEST,
  GET_STUDENT_TESTS,
  GET_UNIT,
  GET_UNITS,
  GET_ACTIVITIES_LIST,
  GET_STUDENT_LEVELS,
  GET_LESSONS,
  GET_LESSON,
}
